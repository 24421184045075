"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetOutputsCodec = exports.assetOutputCodec = exports.AssetOutputCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const array_codec_1 = require("./array-codec");
const compact_int_codec_1 = require("./compact-int-codec");
const signed_int_codec_1 = require("./signed-int-codec");
const long_codec_1 = require("./long-codec");
const bytestring_codec_1 = require("./bytestring-codec");
const lockup_script_codec_1 = require("./lockup-script-codec");
const hash_1 = require("./hash");
const utils_1 = require("../utils");
const token_codec_1 = require("./token-codec");
class AssetOutputCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .nest('amount', {
            type: compact_int_codec_1.compactSignedIntCodec.parser
        })
            .nest('lockupScript', {
            type: lockup_script_codec_1.lockupScriptCodec.parser
        })
            .buffer('lockTime', {
            length: 8
        })
            .nest('tokens', {
            type: token_codec_1.tokensCodec.parser
        })
            .nest('additionalData', {
            type: bytestring_codec_1.byteStringCodec.parser
        });
    }
    encode(input) {
        const amount = compact_int_codec_1.compactUnsignedIntCodec.encode(input.amount);
        const lockupScript = lockup_script_codec_1.lockupScriptCodec.encode(input.lockupScript);
        const tokens = token_codec_1.tokensCodec.encode(input.tokens.value);
        const additionalData = bytestring_codec_1.byteStringCodec.encode(input.additionalData);
        return (0, utils_1.concatBytes)([amount, lockupScript, input.lockTime, tokens, additionalData]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    static toFixedAssetOutputs(txIdBytes, outputs) {
        return outputs.map((output, index) => AssetOutputCodec.toFixedAssetOutput(txIdBytes, output, index));
    }
    static toFixedAssetOutput(txIdBytes, output, index) {
        const attoAlphAmount = compact_int_codec_1.compactUnsignedIntCodec.toU256(output.amount).toString();
        const lockTime = Number(long_codec_1.longCodec.decode(output.lockTime));
        const tokens = output.tokens.value.map((token) => {
            return {
                id: (0, utils_1.binToHex)(token.tokenId),
                amount: compact_int_codec_1.compactUnsignedIntCodec.toU256(token.amount).toString()
            };
        });
        const message = (0, utils_1.binToHex)(output.additionalData.value);
        const scriptType = output.lockupScript.scriptType;
        const key = (0, utils_1.binToHex)((0, hash_1.blakeHash)((0, utils_1.concatBytes)([txIdBytes, signed_int_codec_1.signedIntCodec.encode(index)])));
        const outputLockupScript = output.lockupScript.script;
        const address = utils_1.bs58.encode(lockup_script_codec_1.lockupScriptCodec.encode(output.lockupScript));
        let hint = undefined;
        if (scriptType === 0) {
            // P2PKH
            hint = (0, hash_1.createHint)(outputLockupScript.publicKeyHash);
        }
        else if (scriptType === 1) {
            // P2MPKH
            hint = (0, hash_1.createHint)(outputLockupScript.publicKeyHashes.value[0].publicKeyHash);
        }
        else if (scriptType === 2) {
            // P2SH
            hint = (0, hash_1.createHint)(outputLockupScript.scriptHash);
        }
        else if (scriptType === 3) {
            throw new Error(`P2C script type not allowed for asset output`);
        }
        else {
            throw new Error(`Unexpected output script type: ${scriptType}`);
        }
        return { hint, key, attoAlphAmount, lockTime, tokens, address, message };
    }
    static fromFixedAssetOutputs(fixedOutputs) {
        return fixedOutputs.map((output) => {
            return AssetOutputCodec.fromFixedAssetOutput(output);
        });
    }
    static fromFixedAssetOutput(fixedOutput) {
        const amount = compact_int_codec_1.compactUnsignedIntCodec.fromU256(BigInt(fixedOutput.attoAlphAmount));
        const lockTime = long_codec_1.longCodec.encode(BigInt(fixedOutput.lockTime));
        const lockupScript = lockup_script_codec_1.lockupScriptCodec.decode(utils_1.bs58.decode(fixedOutput.address));
        const tokensValue = fixedOutput.tokens.map((token) => {
            return {
                tokenId: (0, utils_1.hexToBinUnsafe)(token.id),
                amount: compact_int_codec_1.compactUnsignedIntCodec.fromU256(BigInt(token.amount))
            };
        });
        const tokens = {
            length: compact_int_codec_1.compactSignedIntCodec.fromI32(tokensValue.length),
            value: tokensValue
        };
        const additionalDataValue = (0, utils_1.hexToBinUnsafe)(fixedOutput.message);
        const additionalData = {
            length: compact_int_codec_1.compactSignedIntCodec.fromI32(additionalDataValue.length),
            value: additionalDataValue
        };
        return {
            amount,
            lockupScript,
            lockTime,
            tokens,
            additionalData
        };
    }
}
exports.AssetOutputCodec = AssetOutputCodec;
exports.assetOutputCodec = new AssetOutputCodec();
exports.assetOutputsCodec = new array_codec_1.ArrayCodec(exports.assetOutputCodec);
