"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lockupScriptCodec = exports.LockupScriptCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const compact_int_codec_1 = require("./compact-int-codec");
const array_codec_1 = require("./array-codec");
class PublicKeyHashCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start().buffer('publicKeyHash', { length: 32 });
    }
    encode(input) {
        return input.publicKeyHash;
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
const publicKeyHashCodec = new PublicKeyHashCodec();
const publicKeyHashesCodec = new array_codec_1.ArrayCodec(publicKeyHashCodec);
const multiSigParser = binary_parser_1.Parser.start()
    .nest('publicKeyHashes', { type: publicKeyHashesCodec.parser })
    .nest('m', { type: compact_int_codec_1.compactSignedIntCodec.parser });
class LockupScriptCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .uint8('scriptType')
            .choice('script', {
            tag: 'scriptType',
            choices: {
                0: publicKeyHashCodec.parser,
                1: multiSigParser,
                2: binary_parser_1.Parser.start().buffer('scriptHash', { length: 32 }),
                3: binary_parser_1.Parser.start().buffer('contractId', { length: 32 })
            }
        });
    }
    encode(input) {
        const result = [input.scriptType];
        if (input.scriptType === 0) {
            result.push(...input.script.publicKeyHash);
        }
        else if (input.scriptType === 1) {
            result.push(...publicKeyHashesCodec.encode(input.script.publicKeyHashes.value));
            result.push(...compact_int_codec_1.compactSignedIntCodec.encode(input.script.m));
        }
        else if (input.scriptType === 2) {
            result.push(...input.script.scriptHash);
        }
        else if (input.scriptType === 3) {
            result.push(...input.script.contractId);
        }
        else {
            throw new Error(`Unsupported script type: ${input.scriptType}`);
        }
        return new Uint8Array(result);
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
exports.LockupScriptCodec = LockupScriptCodec;
exports.lockupScriptCodec = new LockupScriptCodec();
