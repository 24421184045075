"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contractOutputCodec = exports.ContractOutputCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const compact_int_codec_1 = require("./compact-int-codec");
const token_codec_1 = require("./token-codec");
const hash_1 = require("./hash");
const utils_1 = require("../utils");
const signed_int_codec_1 = require("./signed-int-codec");
const lockup_script_codec_1 = require("./lockup-script-codec");
class ContractOutputCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .nest('amount', {
            type: compact_int_codec_1.compactUnsignedIntCodec.parser
        })
            .nest('lockupScript', {
            type: binary_parser_1.Parser.start().buffer('contractId', { length: 32 })
        })
            .nest('tokens', {
            type: token_codec_1.tokensCodec.parser
        });
    }
    encode(input) {
        const amount = compact_int_codec_1.compactUnsignedIntCodec.encode(input.amount);
        const lockupScript = input.lockupScript.contractId;
        const tokens = token_codec_1.tokensCodec.encode(input.tokens.value);
        return (0, utils_1.concatBytes)([amount, lockupScript, tokens]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    static convertToApiContractOutput(txIdBytes, output, index) {
        const hint = (0, hash_1.createHint)(output.lockupScript.contractId);
        const key = (0, utils_1.binToHex)((0, hash_1.blakeHash)((0, utils_1.concatBytes)([txIdBytes, signed_int_codec_1.signedIntCodec.encode(index)])));
        const attoAlphAmount = compact_int_codec_1.compactUnsignedIntCodec.toU256(output.amount).toString();
        const address = utils_1.bs58.encode(new Uint8Array([0x03, ...output.lockupScript.contractId]));
        const tokens = output.tokens.value.map((token) => {
            return {
                id: (0, utils_1.binToHex)(token.tokenId),
                amount: compact_int_codec_1.compactUnsignedIntCodec.toU256(token.amount).toString()
            };
        });
        return { hint, key, attoAlphAmount, address, tokens, type: 'ContractOutput' };
    }
    static convertToOutput(apiContractOutput) {
        const amount = compact_int_codec_1.compactUnsignedIntCodec.fromU256(BigInt(apiContractOutput.attoAlphAmount));
        const lockupScript = lockup_script_codec_1.lockupScriptCodec.decode(utils_1.bs58.decode(apiContractOutput.address)).script;
        const tokensValue = apiContractOutput.tokens.map((token) => {
            return {
                tokenId: (0, utils_1.hexToBinUnsafe)(token.id),
                amount: compact_int_codec_1.compactUnsignedIntCodec.fromU256(BigInt(token.amount))
            };
        });
        const tokens = {
            length: compact_int_codec_1.compactSignedIntCodec.fromI32(tokensValue.length),
            value: tokensValue
        };
        return { amount, lockupScript, tokens };
    }
}
exports.ContractOutputCodec = ContractOutputCodec;
exports.contractOutputCodec = new ContractOutputCodec();
