"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.byteStringCodec = exports.ByteStringCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const compact_int_codec_1 = require("./compact-int-codec");
const utils_1 = require("../utils");
class ByteStringCodec {
    constructor() {
        this.parser = new binary_parser_1.Parser()
            .nest('length', {
            type: compact_int_codec_1.compactSignedIntCodec.parser
        })
            .buffer('value', {
            length: function (ctx) {
                return compact_int_codec_1.compactSignedIntCodec.toI32(this['length']);
            }
        });
    }
    encode(input) {
        return (0, utils_1.concatBytes)([compact_int_codec_1.compactSignedIntCodec.encode(input.length), input.value]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    encodeBytes(input) {
        return (0, utils_1.concatBytes)([compact_int_codec_1.compactSignedIntCodec.encodeI32(input.length), input]);
    }
    decodeBytes(input) {
        return this.decode(input).value;
    }
}
exports.ByteStringCodec = ByteStringCodec;
exports.byteStringCodec = new ByteStringCodec();
