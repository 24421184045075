"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.methodsCodec = exports.methodCodec = exports.MethodCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const array_codec_1 = require("./array-codec");
const compact_int_codec_1 = require("./compact-int-codec");
const instr_codec_1 = require("./instr-codec");
function decodeAssetModifier(encoded) {
    const usePayToContractOnly = (encoded & 4) !== 0;
    switch (encoded & 3) {
        case 0:
            return { usePayToContractOnly, usePreapprovedAssets: false, useContractAssets: false };
        case 1:
            return { usePayToContractOnly, usePreapprovedAssets: true, useContractAssets: true };
        case 2:
            return { usePayToContractOnly, usePreapprovedAssets: false, useContractAssets: true };
        case 3:
            return { usePayToContractOnly, usePreapprovedAssets: true, useContractAssets: false };
        default:
            throw new Error(`Invalid asset modifier: ${encoded}`);
    }
}
function encodeAssetModifier(arg) {
    const encoded = !arg.usePreapprovedAssets && !arg.useContractAssets
        ? 0
        : arg.usePreapprovedAssets && arg.useContractAssets
            ? 1
            : !arg.usePreapprovedAssets && arg.useContractAssets
                ? 2
                : 3;
    return encoded | (arg.usePayToContractOnly ? 4 : 0);
}
class MethodCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .uint8('isPublic')
            .uint8('assetModifier')
            .nest('argsLength', {
            type: compact_int_codec_1.compactSignedIntCodec.parser
        })
            .nest('localsLength', {
            type: compact_int_codec_1.compactSignedIntCodec.parser
        })
            .nest('returnLength', {
            type: compact_int_codec_1.compactSignedIntCodec.parser
        })
            .nest('instrs', {
            type: instr_codec_1.instrsCodec.parser
        });
    }
    encode(input) {
        const result = [input.isPublic, input.assetModifier];
        result.push(...compact_int_codec_1.compactSignedIntCodec.encode(input.argsLength));
        result.push(...compact_int_codec_1.compactSignedIntCodec.encode(input.localsLength));
        result.push(...compact_int_codec_1.compactSignedIntCodec.encode(input.returnLength));
        result.push(...instr_codec_1.instrsCodec.encode(input.instrs.value));
        return new Uint8Array(result);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    static toMethod(decodedMethod) {
        return {
            isPublic: decodedMethod.isPublic === 1,
            ...decodeAssetModifier(decodedMethod.assetModifier),
            argsLength: compact_int_codec_1.compactSignedIntCodec.toI32(decodedMethod.argsLength),
            localsLength: compact_int_codec_1.compactSignedIntCodec.toI32(decodedMethod.localsLength),
            returnLength: compact_int_codec_1.compactSignedIntCodec.toI32(decodedMethod.returnLength),
            instrs: decodedMethod.instrs.value
        };
    }
    static fromMethod(method) {
        return {
            isPublic: method.isPublic ? 1 : 0,
            assetModifier: encodeAssetModifier(method),
            argsLength: compact_int_codec_1.compactSignedIntCodec.fromI32(method.argsLength),
            localsLength: compact_int_codec_1.compactSignedIntCodec.fromI32(method.localsLength),
            returnLength: compact_int_codec_1.compactSignedIntCodec.fromI32(method.returnLength),
            instrs: instr_codec_1.instrsCodec.fromArray(method.instrs)
        };
    }
}
exports.MethodCodec = MethodCodec;
exports.methodCodec = new MethodCodec();
exports.methodsCodec = new array_codec_1.ArrayCodec(exports.methodCodec);
