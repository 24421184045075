"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signedIntCodec = exports.SignedIntCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const codec_1 = require("./codec");
class SignedIntCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start().buffer('value', {
            length: 4
        });
    }
    encode(value) {
        return new Uint8Array([(value >> 24) & 0xff, (value >> 16) & 0xff, (value >> 8) & 0xff, value & 0xff]);
    }
    decode(bytes) {
        (0, codec_1.assert)(bytes.length === 4, 'Length should be 4');
        return ((bytes[0] & 0xff) << 24) | ((bytes[1] & 0xff) << 16) | ((bytes[2] & 0xff) << 8) | (bytes[3] & 0xff);
    }
}
exports.SignedIntCodec = SignedIntCodec;
exports.signedIntCodec = new SignedIntCodec();
