"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.transactionCodec = exports.TransactionCodec = void 0;
const binary_parser_1 = require("binary-parser");
const unsigned_tx_codec_1 = require("./unsigned-tx-codec");
const signature_codec_1 = require("./signature-codec");
const contract_output_ref_codec_1 = require("./contract-output-ref-codec");
const asset_output_codec_1 = require("./asset-output-codec");
const contract_output_codec_1 = require("./contract-output-codec");
const utils_1 = require("../utils");
const output_codec_1 = require("./output-codec");
class TransactionCodec {
    constructor() {
        this.parser = new binary_parser_1.Parser()
            .nest('unsigned', {
            type: unsigned_tx_codec_1.unsignedTxCodec.parser
        })
            .uint8('scriptExecutionOk')
            .nest('contractInputs', {
            type: contract_output_ref_codec_1.contractOutputRefsCodec.parser
        })
            .nest('generatedOutputs', {
            type: output_codec_1.outputsCodec.parser
        })
            .nest('inputSignatures', {
            type: signature_codec_1.signaturesCodec.parser
        })
            .nest('scriptSignatures', {
            type: signature_codec_1.signaturesCodec.parser
        });
    }
    encode(decodedTx) {
        return (0, utils_1.concatBytes)([
            unsigned_tx_codec_1.unsignedTxCodec.encode(decodedTx.unsigned),
            new Uint8Array([decodedTx.scriptExecutionOk]),
            contract_output_ref_codec_1.contractOutputRefsCodec.encode(decodedTx.contractInputs.value),
            output_codec_1.outputsCodec.encode(decodedTx.generatedOutputs.value),
            signature_codec_1.signaturesCodec.encode(decodedTx.inputSignatures.value),
            signature_codec_1.signaturesCodec.encode(decodedTx.scriptSignatures.value)
        ]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    encodeApiTransaction(input) {
        const decodedTx = TransactionCodec.fromApiTransaction(input);
        return this.encode(decodedTx);
    }
    decodeApiTransaction(input) {
        const decodedTx = this.parser.parse(input);
        return TransactionCodec.toApiTransaction(decodedTx);
    }
    static toApiTransaction(transaction) {
        const txId = unsigned_tx_codec_1.UnsignedTxCodec.txId(transaction.unsigned);
        const unsigned = unsigned_tx_codec_1.UnsignedTxCodec.toApiUnsignedTx(transaction.unsigned);
        const scriptExecutionOk = !!transaction.scriptExecutionOk;
        const contractInputs = transaction.contractInputs.value.map((contractInput) => {
            const hint = contractInput.hint;
            const key = (0, utils_1.binToHex)(contractInput.key);
            return { hint, key };
        });
        const txIdBytes = (0, utils_1.hexToBinUnsafe)(txId);
        const generatedOutputs = transaction.generatedOutputs.value.map((output, index) => {
            if (output.either === 0) {
                const fixedAssetOutput = asset_output_codec_1.AssetOutputCodec.toFixedAssetOutput(txIdBytes, output.value, index);
                return { ...fixedAssetOutput, type: 'AssetOutput' };
            }
            else {
                return contract_output_codec_1.ContractOutputCodec.convertToApiContractOutput(txIdBytes, output.value, index);
            }
        });
        const inputSignatures = transaction.inputSignatures.value.map((signature) => (0, utils_1.binToHex)(signature.value));
        const scriptSignatures = transaction.scriptSignatures.value.map((signature) => (0, utils_1.binToHex)(signature.value));
        return { unsigned, scriptExecutionOk, contractInputs, generatedOutputs, inputSignatures, scriptSignatures };
    }
    static fromApiTransaction(tx) {
        const unsigned = unsigned_tx_codec_1.UnsignedTxCodec.fromApiUnsignedTx(tx.unsigned);
        const scriptExecutionOk = tx.scriptExecutionOk ? 1 : 0;
        const contractInputs = tx.contractInputs.map((contractInput) => {
            return { hint: contractInput.hint, key: (0, utils_1.hexToBinUnsafe)(contractInput.key) };
        });
        const generatedOutputs = tx.generatedOutputs.map((output) => {
            if (output.type === 'AssetOutput') {
                return output_codec_1.outputCodec.fromLeft(asset_output_codec_1.AssetOutputCodec.fromFixedAssetOutput(output));
            }
            else if (output.type === 'ContractOutput') {
                return output_codec_1.outputCodec.fromRight(contract_output_codec_1.ContractOutputCodec.convertToOutput(output));
            }
            else {
                throw new Error('Invalid output type');
            }
        });
        const inputSignatures = tx.inputSignatures.map((signature) => {
            return { value: (0, utils_1.hexToBinUnsafe)(signature) };
        });
        const scriptSignatures = tx.scriptSignatures.map((signature) => {
            return { value: (0, utils_1.hexToBinUnsafe)(signature) };
        });
        return {
            unsigned,
            scriptExecutionOk,
            contractInputs: contract_output_ref_codec_1.contractOutputRefsCodec.fromArray(contractInputs),
            generatedOutputs: output_codec_1.outputsCodec.fromArray(generatedOutputs),
            inputSignatures: signature_codec_1.signaturesCodec.fromArray(inputSignatures),
            scriptSignatures: signature_codec_1.signaturesCodec.fromArray(scriptSignatures)
        };
    }
}
exports.TransactionCodec = TransactionCodec;
exports.transactionCodec = new TransactionCodec();
