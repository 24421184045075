"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputsCodec = exports.inputCodec = exports.InputCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const utils_1 = require("../utils");
const unlock_script_codec_1 = require("./unlock-script-codec");
const signed_int_codec_1 = require("./signed-int-codec");
const array_codec_1 = require("./array-codec");
class InputCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .nest('outputRef', {
            type: binary_parser_1.Parser.start().int32('hint').buffer('key', { length: 32 })
        })
            .nest('unlockScript', {
            type: unlock_script_codec_1.unlockScriptCodec.parser
        });
    }
    encode(input) {
        return (0, utils_1.concatBytes)([
            signed_int_codec_1.signedIntCodec.encode(input.outputRef.hint),
            input.outputRef.key,
            unlock_script_codec_1.unlockScriptCodec.encode(input.unlockScript)
        ]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    static toAssetInputs(inputs) {
        return inputs.map((input) => {
            const hint = input.outputRef.hint;
            const key = (0, utils_1.binToHex)(input.outputRef.key);
            const unlockScript = unlock_script_codec_1.unlockScriptCodec.encode(input.unlockScript);
            return {
                outputRef: { hint, key },
                unlockScript: (0, utils_1.binToHex)(unlockScript)
            };
        });
    }
    static fromAssetInputs(inputs) {
        return inputs.map((input) => {
            const hint = input.outputRef.hint;
            const key = (0, utils_1.hexToBinUnsafe)(input.outputRef.key);
            const unlockScript = unlock_script_codec_1.unlockScriptCodec.decode((0, utils_1.hexToBinUnsafe)(input.unlockScript));
            return {
                outputRef: { hint, key },
                unlockScript
            };
        });
    }
}
exports.InputCodec = InputCodec;
exports.inputCodec = new InputCodec();
exports.inputsCodec = new array_codec_1.ArrayCodec(exports.inputCodec);
