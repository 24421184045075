"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.tokensCodec = exports.tokenCodec = exports.TokenCodec = void 0;
const binary_parser_1 = require("binary-parser");
const compact_int_codec_1 = require("./compact-int-codec");
const array_codec_1 = require("./array-codec");
const utils_1 = require("../utils");
class TokenCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .buffer('tokenId', {
            length: 32
        })
            .nest('amount', {
            type: compact_int_codec_1.compactUnsignedIntCodec.parser
        });
    }
    encode(input) {
        const tokenId = input.tokenId;
        const amount = compact_int_codec_1.compactUnsignedIntCodec.encode(input.amount);
        return (0, utils_1.concatBytes)([tokenId, amount]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
exports.TokenCodec = TokenCodec;
exports.tokenCodec = new TokenCodec();
exports.tokensCodec = new array_codec_1.ArrayCodec(exports.tokenCodec);
