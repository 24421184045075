"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.statefulScriptCodecOpt = exports.scriptCodec = exports.ScriptCodec = void 0;
const binary_parser_1 = require("binary-parser");
const method_codec_1 = require("./method-codec");
const option_codec_1 = require("./option-codec");
const compact_int_codec_1 = require("./compact-int-codec");
class ScriptCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start().nest('methods', {
            type: method_codec_1.methodsCodec.parser
        });
    }
    encode(input) {
        return method_codec_1.methodsCodec.encode(input.methods.value);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    decodeScript(input) {
        const decodedTxScript = this.decode(input);
        const methods = decodedTxScript.methods.value.map((decodedMethod) => method_codec_1.MethodCodec.toMethod(decodedMethod));
        return { methods };
    }
    encodeScript(inputTxScript) {
        const methodLength = compact_int_codec_1.compactSignedIntCodec.fromI32(inputTxScript.methods.length);
        const decodedMethods = inputTxScript.methods.map((method) => method_codec_1.MethodCodec.fromMethod(method));
        return this.encode({ methods: { value: decodedMethods, length: methodLength } });
    }
}
exports.ScriptCodec = ScriptCodec;
exports.scriptCodec = new ScriptCodec();
exports.statefulScriptCodecOpt = new option_codec_1.OptionCodec(exports.scriptCodec);
