"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signaturesCodec = exports.signatureCodec = exports.SignatureCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const array_codec_1 = require("./array-codec");
class SignatureCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start().buffer('value', { length: 64 });
    }
    encode(input) {
        return input.value;
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
exports.SignatureCodec = SignatureCodec;
exports.signatureCodec = new SignatureCodec();
exports.signaturesCodec = new array_codec_1.ArrayCodec(exports.signatureCodec);
