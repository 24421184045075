"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compactSignedIntCodec = exports.CompactSignedIntCodec = exports.compactUnsignedIntCodec = exports.CompactUnsignedIntCodec = exports.CompactInt = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const codec_1 = require("./codec");
const bigint_codec_1 = require("./bigint-codec");
const utils_1 = require("../utils");
class CompactInt {
}
exports.CompactInt = CompactInt;
CompactInt.oneBytePrefix = 0x00;
CompactInt.oneByteNegPrefix = 0xc0;
CompactInt.twoBytePrefix = 0x40;
CompactInt.twoByteNegPrefix = 0x80;
CompactInt.fourBytePrefix = 0x80;
CompactInt.fourByteNegPrefix = 0x40;
CompactInt.multiBytePrefix = 0xc0;
const maskRest = 0xc0;
const maskMode = 0x3f;
const maskModeNeg = 0xffffffc0;
const signFlag = 0x20; // 0b00100000
const compactIntParser = new binary_parser_1.Parser().uint8('mode').buffer('rest', {
    length: function (ctx) {
        const rawMode = this['mode'];
        const mode = rawMode & maskRest;
        switch (mode) {
            case CompactInt.oneBytePrefix:
                return 0;
            case CompactInt.twoBytePrefix:
                return 1;
            case CompactInt.fourBytePrefix:
                return 3;
            default:
                return (rawMode & maskMode) + 4;
        }
    }
});
class CompactUnsignedIntCodec {
    constructor() {
        this.oneByteBound = 0x40;
        this.twoByteBound = this.oneByteBound << 8;
        this.fourByteBound = this.oneByteBound << (8 * 3);
        this.parser = compactIntParser;
    }
    encode(input) {
        return new Uint8Array([input.mode, ...input.rest]);
    }
    encodeU32(value) {
        if (value < this.oneByteBound) {
            return new Uint8Array([(CompactInt.oneBytePrefix + value) & 0xff]);
        }
        else if (value < this.twoByteBound) {
            return new Uint8Array([(CompactInt.twoBytePrefix + (value >> 8)) & 0xff, value & 0xff]);
        }
        else if (value < this.fourByteBound) {
            return new Uint8Array([
                (CompactInt.fourBytePrefix + (value >> 24)) & 0xff,
                (value >> 16) & 0xff,
                (value >> 8) & 0xff,
                value & 0xff
            ]);
        }
        else {
            return new Uint8Array([
                CompactInt.multiBytePrefix,
                (value >> 24) & 0xff,
                (value >> 16) & 0xff,
                (value >> 8) & 0xff,
                value & 0xff
            ]);
        }
    }
    encodeU256(value) {
        (0, codec_1.assert)(value >= 0n, 'Value should be positive');
        if (value < this.fourByteBound) {
            return this.encodeU32(Number(value));
        }
        else {
            let bytes = bigint_codec_1.BigIntCodec.encode(value);
            if (bytes[0] === 0) {
                bytes = bytes.slice(1);
            }
            (0, codec_1.assert)(bytes.length <= 32, 'Expect <= 32 bytes for U256');
            const header = (bytes.length - 4 + CompactInt.multiBytePrefix) & 0xff;
            return new Uint8Array([header, ...bytes]);
        }
    }
    decodeU256(input) {
        const decoded = this.decode(input);
        return this.toU256(decoded);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    toU256(value) {
        const mode = value.mode & maskRest;
        if (fixedSize(mode)) {
            const body = new Uint8Array([value.mode, ...value.rest]);
            return BigInt(decodePositiveInt(value.mode, body));
        }
        else {
            (0, codec_1.assert)(value.rest.length <= 32, 'Expect <= 32 bytes for U256');
            return bigint_codec_1.BigIntCodec.decode(value.rest, false);
        }
    }
    fromU256(value) {
        return this.decode(this.encodeU256(value));
    }
}
exports.CompactUnsignedIntCodec = CompactUnsignedIntCodec;
exports.compactUnsignedIntCodec = new CompactUnsignedIntCodec();
class CompactSignedIntCodec {
    constructor() {
        this.signFlag = 0x20; // 0b00100000
        this.oneByteBound = 0x20; // 0b00100000
        this.twoByteBound = this.oneByteBound << 8;
        this.fourByteBound = this.oneByteBound << (8 * 3);
        this.parser = compactIntParser;
    }
    encode(input) {
        return new Uint8Array([input.mode, ...input.rest]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    decodeI32(input) {
        const decoded = this.decode(input);
        return this.toI32(decoded);
    }
    encodeI32(value) {
        if (value >= 0) {
            if (value < this.oneByteBound) {
                return new Uint8Array([(CompactInt.oneBytePrefix + value) & 0xff]);
            }
            else if (value < this.twoByteBound) {
                return new Uint8Array([(CompactInt.twoBytePrefix + (value >> 8)) & 0xff, value & 0xff]);
            }
            else if (value < this.fourByteBound) {
                return new Uint8Array([
                    (CompactInt.fourBytePrefix + (value >> 24)) & 0xff,
                    (value >> 16) & 0xff,
                    (value >> 8) & 0xff,
                    value & 0xff
                ]);
            }
            else {
                return new Uint8Array([
                    CompactInt.multiBytePrefix,
                    (value >> 24) & 0xff,
                    (value >> 16) & 0xff,
                    (value >> 8) & 0xff,
                    value & 0xff
                ]);
            }
        }
        else {
            if (value >= -this.oneByteBound) {
                return new Uint8Array([(value ^ CompactInt.oneByteNegPrefix) & 0xff]);
            }
            else if (value >= -this.twoByteBound) {
                return new Uint8Array([((value >> 8) ^ CompactInt.twoByteNegPrefix) & 0xff, value & 0xff]);
            }
            else if (value >= -this.fourByteBound) {
                return new Uint8Array([
                    ((value >> 24) ^ CompactInt.fourByteNegPrefix) & 0xff,
                    (value >> 16) & 0xff,
                    (value >> 8) & 0xff,
                    value & 0xff
                ]);
            }
            else {
                return new Uint8Array([
                    CompactInt.multiBytePrefix,
                    (value >> 24) & 0xff,
                    (value >> 16) & 0xff,
                    (value >> 8) & 0xff,
                    value & 0xff
                ]);
            }
        }
    }
    encodeI256(value) {
        if (value >= -0x20000000 && value < 0x20000000) {
            return this.encodeI32(Number(value));
        }
        else {
            const bytes = bigint_codec_1.BigIntCodec.encode(value);
            const header = (bytes.length - 4 + CompactInt.multiBytePrefix) & 0xff;
            return new Uint8Array([header, ...bytes]);
        }
    }
    decodeI256(input) {
        const decoded = this.decode(input);
        return this.toI256(decoded);
    }
    toI32(value) {
        const body = new Uint8Array([value.mode, ...value.rest]);
        const mode = value.mode & maskRest;
        if (fixedSize(mode)) {
            const isPositive = (value.mode & signFlag) == 0;
            if (isPositive) {
                return decodePositiveInt(value.mode, body);
            }
            else {
                return decodeNegativeInt(value.mode, body);
            }
        }
        else {
            if (body.length === 5) {
                return ((body[1] & 0xff) << 24) | ((body[2] & 0xff) << 16) | ((body[3] & 0xff) << 8) | (body[4] & 0xff);
            }
            else {
                throw new Error(`Expect 4 bytes int, but get ${body.length - 1} bytes int`);
            }
        }
    }
    fromI32(value) {
        return this.decode(this.encodeI32(value));
    }
    toI256(value) {
        const mode = value.mode & maskRest;
        if (fixedSize(mode)) {
            return BigInt(this.toI32(value));
        }
        else {
            (0, codec_1.assert)(value.rest.length <= 32, 'Expect <= 32 bytes for I256');
            return bigint_codec_1.BigIntCodec.decode(value.rest, true);
        }
    }
    fromI256(value) {
        return this.decode(this.encodeI256(value));
    }
}
exports.CompactSignedIntCodec = CompactSignedIntCodec;
exports.compactSignedIntCodec = new CompactSignedIntCodec();
function decodePositiveInt(rawMode, body) {
    const mode = rawMode & maskRest;
    switch (mode) {
        case CompactInt.oneBytePrefix:
            return rawMode;
        case CompactInt.twoBytePrefix:
            (0, codec_1.assert)(body.length === 2, 'Length should be 2');
            return ((body[0] & maskMode) << 8) | (body[1] & 0xff);
        case CompactInt.fourBytePrefix:
            (0, codec_1.assert)(body.length === 4, 'Length should be 4');
            return ((body[0] & maskMode) << 24) | ((body[1] & 0xff) << 16) | ((body[2] & 0xff) << 8) | (body[3] & 0xff);
        default:
            if (body.length === 5) {
                return Number(BigInt('0x' + (0, utils_1.binToHex)(body.slice(1))));
            }
            else {
                throw new Error(`decodePositiveInt: Expect 4 bytes int, but get ${body.length - 1} bytes int`);
            }
    }
}
function decodeNegativeInt(rawMode, body) {
    const mode = rawMode & maskRest;
    switch (mode) {
        case CompactInt.oneBytePrefix:
            return rawMode | maskModeNeg;
        case CompactInt.twoBytePrefix:
            (0, codec_1.assert)(body.length === 2, 'Length should be 2');
            return ((body[0] | maskModeNeg) << 8) | (body[1] & 0xff);
        case CompactInt.fourBytePrefix:
            (0, codec_1.assert)(body.length === 4, 'Length should be 4');
            return ((body[0] | maskModeNeg) << 24) | ((body[1] & 0xff) << 16) | ((body[2] & 0xff) << 8) | (body[3] & 0xff);
        default:
            throw new Error(`decodeNegativeInt: Expect 4 bytes int, but get ${body.length - 1} bytes int`);
    }
}
function fixedSize(mode) {
    return mode === CompactInt.oneBytePrefix || mode === CompactInt.twoBytePrefix || mode === CompactInt.fourBytePrefix;
}
