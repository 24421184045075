"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
class OptionCodec {
    constructor(childCodec, parser = new binary_parser_1.Parser().uint8('option').choice('value', {
        tag: 'option',
        choices: {
            0: new binary_parser_1.Parser(),
            1: childCodec.parser
        }
    })) {
        this.childCodec = childCodec;
        this.parser = parser;
    }
    encode(input) {
        const result = [input.option];
        if (input.option === 1) {
            result.push(...this.childCodec.encode(input.value));
        }
        return new Uint8Array(result);
    }
    decode(input) {
        const result = this.parser.parse(input);
        return {
            ...result,
            value: result.option ? this.childCodec.decode(result.value.value) : undefined
        };
    }
    fromBytes(input) {
        return {
            option: input ? 1 : 0,
            value: input ? this.childCodec.decode(input) : undefined
        };
    }
}
exports.OptionCodec = OptionCodec;
