"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.U256Lt = exports.U256Neq = exports.U256Eq = exports.U256Mod = exports.U256Div = exports.U256Mul = exports.U256Sub = exports.U256Add = exports.I256Ge = exports.I256Gt = exports.I256Le = exports.I256Lt = exports.I256Neq = exports.I256Eq = exports.I256Mod = exports.I256Div = exports.I256Mul = exports.I256Sub = exports.I256Add = exports.BoolToByteVec = exports.BoolNeq = exports.BoolEq = exports.BoolOr = exports.BoolAnd = exports.BoolNot = exports.Pop = exports.StoreLocal = exports.LoadLocal = exports.AddressConst = exports.ByteConst = exports.U256Const = exports.I256Const = exports.U256Const5 = exports.U256Const4 = exports.U256Const3 = exports.U256Const2 = exports.U256Const1 = exports.U256Const0 = exports.I256ConstN1 = exports.I256Const5 = exports.I256Const4 = exports.I256Const3 = exports.I256Const2 = exports.I256Const1 = exports.I256Const0 = exports.ConstFalse = exports.ConstTrue = exports.Return = exports.CallExternal = exports.CallLocal = void 0;
exports.ByteVecToAddress = exports.ByteVecSlice = exports.Log5 = exports.Log4 = exports.Log3 = exports.Log2 = exports.Log1 = exports.VerifyRelativeLocktime = exports.VerifyAbsoluteLocktime = exports.TxInputsSize = exports.TxInputAddressAt = exports.TxId = exports.BlockTarget = exports.BlockTimeStamp = exports.NetworkId = exports.VerifyED25519 = exports.VerifySecP256K1 = exports.VerifyTxSignature = exports.Sha3 = exports.Sha256 = exports.Keccak256 = exports.Blake2b = exports.Assert = exports.IfFalse = exports.IfTrue = exports.Jump = exports.IsContractAddress = exports.IsAssetAddress = exports.AddressToByteVec = exports.AddressNeq = exports.AddressEq = exports.ByteVecConcat = exports.ByteVecSize = exports.ByteVecNeq = exports.ByteVecEq = exports.U256ToByteVec = exports.U256ToI256 = exports.I256ToByteVec = exports.I256ToU256 = exports.U256SHR = exports.U256SHL = exports.U256Xor = exports.U256BitOr = exports.U256BitAnd = exports.U256ModMul = exports.U256ModSub = exports.U256ModAdd = exports.U256Ge = exports.U256Gt = exports.U256Le = void 0;
exports.TransferAlphFromSelf = exports.TransferAlph = exports.IsPaying = exports.TokenRemaining = exports.AlphRemaining = exports.ApproveToken = exports.ApproveAlph = exports.StoreMutField = exports.LoadMutField = exports.GroupOfAddress = exports.BoolToString = exports.I256ToString = exports.U256ToString = exports.AddModN = exports.MulModN = exports.GetSegragatedSignature = exports.VerifyBIP340Schnorr = exports.U256ModExp = exports.U256Exp = exports.I256Exp = exports.TxGasFee = exports.TxGasAmount = exports.TxGasPrice = exports.DEBUG = exports.BlockHash = exports.Swap = exports.AssertWithErrorCode = exports.Dup = exports.StoreLocalByIndex = exports.LoadLocalByIndex = exports.ContractIdToAddress = exports.Log9 = exports.Log8 = exports.Log7 = exports.Log6 = exports.EthEcRecover = exports.U256From32Byte = exports.U256From16Byte = exports.U256From8Byte = exports.U256From4Byte = exports.U256From2Byte = exports.U256From1Byte = exports.U256To32Byte = exports.U256To16Byte = exports.U256To8Byte = exports.U256To4Byte = exports.U256To2Byte = exports.U256To1Byte = exports.Zeros = exports.Encode = void 0;
exports.instrsCodec = exports.instrCodec = exports.InstrCodec = exports.CallExternalBySelector = exports.MethodSelector = exports.CreateMapEntry = exports.MinimalContractDeposit = exports.PayGasFee = exports.LoadImmFieldByIndex = exports.LoadImmField = exports.AlphTokenId = exports.SubContractIdOf = exports.SubContractId = exports.NullContractAddress = exports.CopyCreateSubContractAndTransferToken = exports.CreateSubContractAndTransferToken = exports.CopyCreateContractAndTransferToken = exports.CreateContractAndTransferToken = exports.ContractExists = exports.StoreMutFieldByIndex = exports.LoadMutFieldByIndex = exports.CopyCreateSubContractWithToken = exports.CopyCreateSubContract = exports.CreateSubContractWithToken = exports.CreateSubContract = exports.LockApprovedAssets = exports.BurnToken = exports.CopyCreateContractWithToken = exports.MigrateWithFields = exports.MigrateSimple = exports.ContractInitialCodeHash = exports.ContractInitialStateHash = exports.CallerCodeHash = exports.CallerInitialStateHash = exports.IsCallerFromTxScript = exports.CallerAddress = exports.CallerContractId = exports.SelfAddress = exports.SelfContractId = exports.DestroySelf = exports.CopyCreateContract = exports.CreateContractWithToken = exports.CreateContract = exports.TransferTokenToSelf = exports.TransferTokenFromSelf = exports.TransferToken = exports.TransferAlphToSelf = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const array_codec_1 = require("./array-codec");
const compact_int_codec_1 = require("./compact-int-codec");
const bytestring_codec_1 = require("./bytestring-codec");
const lockup_script_codec_1 = require("./lockup-script-codec");
const signed_int_codec_1 = require("./signed-int-codec");
const byteStringArrayCodec = new array_codec_1.ArrayCodec(bytestring_codec_1.byteStringCodec);
const CallLocal = (index) => ({ code: 0x00, value: { index } });
exports.CallLocal = CallLocal;
const CallExternal = (index) => ({ code: 0x01, value: { index } });
exports.CallExternal = CallExternal;
exports.Return = { code: 0x02, value: {} };
exports.ConstTrue = { code: 0x03, value: {} };
exports.ConstFalse = { code: 0x04, value: {} };
exports.I256Const0 = { code: 0x05, value: {} };
exports.I256Const1 = { code: 0x06, value: {} };
exports.I256Const2 = { code: 0x07, value: {} };
exports.I256Const3 = { code: 0x08, value: {} };
exports.I256Const4 = { code: 0x09, value: {} };
exports.I256Const5 = { code: 0x0a, value: {} };
exports.I256ConstN1 = { code: 0x0b, value: {} };
exports.U256Const0 = { code: 0x0c, value: {} };
exports.U256Const1 = { code: 0x0d, value: {} };
exports.U256Const2 = { code: 0x0e, value: {} };
exports.U256Const3 = { code: 0x0f, value: {} };
exports.U256Const4 = { code: 0x10, value: {} };
exports.U256Const5 = { code: 0x11, value: {} };
const I256Const = (value) => ({ code: 0x12, value: { value } });
exports.I256Const = I256Const;
const U256Const = (value) => ({ code: 0x13, value: { value } });
exports.U256Const = U256Const;
const ByteConst = (value) => ({ code: 0x14, value: { value } });
exports.ByteConst = ByteConst;
const AddressConst = (value) => ({ code: 0x15, value: { value } });
exports.AddressConst = AddressConst;
const LoadLocal = (index) => ({ code: 0x16, value: { index } });
exports.LoadLocal = LoadLocal;
const StoreLocal = (index) => ({ code: 0x17, value: { index } });
exports.StoreLocal = StoreLocal;
exports.Pop = { code: 0x18, value: {} };
exports.BoolNot = { code: 0x19, value: {} };
exports.BoolAnd = { code: 0x1a, value: {} };
exports.BoolOr = { code: 0x1b, value: {} };
exports.BoolEq = { code: 0x1c, value: {} };
exports.BoolNeq = { code: 0x1d, value: {} };
exports.BoolToByteVec = { code: 0x1e, value: {} };
exports.I256Add = { code: 0x1f, value: {} };
exports.I256Sub = { code: 0x20, value: {} };
exports.I256Mul = { code: 0x21, value: {} };
exports.I256Div = { code: 0x22, value: {} };
exports.I256Mod = { code: 0x23, value: {} };
exports.I256Eq = { code: 0x24, value: {} };
exports.I256Neq = { code: 0x25, value: {} };
exports.I256Lt = { code: 0x26, value: {} };
exports.I256Le = { code: 0x27, value: {} };
exports.I256Gt = { code: 0x28, value: {} };
exports.I256Ge = { code: 0x29, value: {} };
exports.U256Add = { code: 0x2a, value: {} };
exports.U256Sub = { code: 0x2b, value: {} };
exports.U256Mul = { code: 0x2c, value: {} };
exports.U256Div = { code: 0x2d, value: {} };
exports.U256Mod = { code: 0x2e, value: {} };
exports.U256Eq = { code: 0x2f, value: {} };
exports.U256Neq = { code: 0x30, value: {} };
exports.U256Lt = { code: 0x31, value: {} };
exports.U256Le = { code: 0x32, value: {} };
exports.U256Gt = { code: 0x33, value: {} };
exports.U256Ge = { code: 0x34, value: {} };
exports.U256ModAdd = { code: 0x35, value: {} };
exports.U256ModSub = { code: 0x36, value: {} };
exports.U256ModMul = { code: 0x37, value: {} };
exports.U256BitAnd = { code: 0x38, value: {} };
exports.U256BitOr = { code: 0x39, value: {} };
exports.U256Xor = { code: 0x3a, value: {} };
exports.U256SHL = { code: 0x3b, value: {} };
exports.U256SHR = { code: 0x3c, value: {} };
exports.I256ToU256 = { code: 0x3d, value: {} };
exports.I256ToByteVec = { code: 0x3e, value: {} };
exports.U256ToI256 = { code: 0x3f, value: {} };
exports.U256ToByteVec = { code: 0x40, value: {} };
exports.ByteVecEq = { code: 0x41, value: {} };
exports.ByteVecNeq = { code: 0x42, value: {} };
exports.ByteVecSize = { code: 0x43, value: {} };
exports.ByteVecConcat = { code: 0x44, value: {} };
exports.AddressEq = { code: 0x45, value: {} };
exports.AddressNeq = { code: 0x46, value: {} };
exports.AddressToByteVec = { code: 0x47, value: {} };
exports.IsAssetAddress = { code: 0x48, value: {} };
exports.IsContractAddress = { code: 0x49, value: {} };
const Jump = (value) => ({ code: 0x4a, value });
exports.Jump = Jump;
const IfTrue = (value) => ({ code: 0x4b, value });
exports.IfTrue = IfTrue;
const IfFalse = (value) => ({ code: 0x4c, value: { value } });
exports.IfFalse = IfFalse;
exports.Assert = { code: 0x4d, value: {} };
exports.Blake2b = { code: 0x4e, value: {} };
exports.Keccak256 = { code: 0x4f, value: {} };
exports.Sha256 = { code: 0x50, value: {} };
exports.Sha3 = { code: 0x51, value: {} };
exports.VerifyTxSignature = { code: 0x52, value: {} };
exports.VerifySecP256K1 = { code: 0x53, value: {} };
exports.VerifyED25519 = { code: 0x54, value: {} };
exports.NetworkId = { code: 0x55, value: {} };
exports.BlockTimeStamp = { code: 0x56, value: {} };
exports.BlockTarget = { code: 0x57, value: {} };
exports.TxId = { code: 0x58, value: {} };
exports.TxInputAddressAt = { code: 0x59, value: {} };
exports.TxInputsSize = { code: 0x5a, value: {} };
exports.VerifyAbsoluteLocktime = { code: 0x5b, value: {} };
exports.VerifyRelativeLocktime = { code: 0x5c, value: {} };
exports.Log1 = { code: 0x5d, value: {} };
exports.Log2 = { code: 0x5e, value: {} };
exports.Log3 = { code: 0x5f, value: {} };
exports.Log4 = { code: 0x60, value: {} };
exports.Log5 = { code: 0x61, value: {} };
exports.ByteVecSlice = { code: 0x62, value: {} };
exports.ByteVecToAddress = { code: 0x63, value: {} };
exports.Encode = { code: 0x64, value: {} };
exports.Zeros = { code: 0x65, value: {} };
exports.U256To1Byte = { code: 0x66, value: {} };
exports.U256To2Byte = { code: 0x67, value: {} };
exports.U256To4Byte = { code: 0x68, value: {} };
exports.U256To8Byte = { code: 0x69, value: {} };
exports.U256To16Byte = { code: 0x6a, value: {} };
exports.U256To32Byte = { code: 0x6b, value: {} };
exports.U256From1Byte = { code: 0x6c, value: {} };
exports.U256From2Byte = { code: 0x6d, value: {} };
exports.U256From4Byte = { code: 0x6e, value: {} };
exports.U256From8Byte = { code: 0x6f, value: {} };
exports.U256From16Byte = { code: 0x70, value: {} };
exports.U256From32Byte = { code: 0x71, value: {} };
exports.EthEcRecover = { code: 0x72, value: {} };
exports.Log6 = { code: 0x73, value: {} };
exports.Log7 = { code: 0x74, value: {} };
exports.Log8 = { code: 0x75, value: {} };
exports.Log9 = { code: 0x76, value: {} };
exports.ContractIdToAddress = { code: 0x77, value: {} };
exports.LoadLocalByIndex = { code: 0x78, value: {} };
exports.StoreLocalByIndex = { code: 0x79, value: {} };
exports.Dup = { code: 0x7a, value: {} };
exports.AssertWithErrorCode = { code: 0x7b, value: {} };
exports.Swap = { code: 0x7c, value: {} };
exports.BlockHash = { code: 0x7d, value: {} };
const DEBUG = (stringParts) => ({ code: 0x7e, value: { stringParts } });
exports.DEBUG = DEBUG;
exports.TxGasPrice = { code: 0x7f, value: {} };
exports.TxGasAmount = { code: 0x80, value: {} };
exports.TxGasFee = { code: 0x81, value: {} };
exports.I256Exp = { code: 0x82, value: {} };
exports.U256Exp = { code: 0x83, value: {} };
exports.U256ModExp = { code: 0x84, value: {} };
exports.VerifyBIP340Schnorr = { code: 0x85, value: {} };
exports.GetSegragatedSignature = { code: 0x86, value: {} };
exports.MulModN = { code: 0x87, value: {} };
exports.AddModN = { code: 0x88, value: {} };
exports.U256ToString = { code: 0x89, value: {} };
exports.I256ToString = { code: 0x8a, value: {} };
exports.BoolToString = { code: 0x8b, value: {} };
exports.GroupOfAddress = { code: 0x8c, value: {} };
const LoadMutField = (index) => ({ code: 0xa0, value: { index } });
exports.LoadMutField = LoadMutField;
const StoreMutField = (index) => ({ code: 0xa1, value: { index } });
exports.StoreMutField = StoreMutField;
exports.ApproveAlph = { code: 0xa2, value: {} };
exports.ApproveToken = { code: 0xa3, value: {} };
exports.AlphRemaining = { code: 0xa4, value: {} };
exports.TokenRemaining = { code: 0xa5, value: {} };
exports.IsPaying = { code: 0xa6, value: {} };
exports.TransferAlph = { code: 0xa7, value: {} };
exports.TransferAlphFromSelf = { code: 0xa8, value: {} };
exports.TransferAlphToSelf = { code: 0xa9, value: {} };
exports.TransferToken = { code: 0xaa, value: {} };
exports.TransferTokenFromSelf = { code: 0xab, value: {} };
exports.TransferTokenToSelf = { code: 0xac, value: {} };
exports.CreateContract = { code: 0xad, value: {} };
exports.CreateContractWithToken = { code: 0xae, value: {} };
exports.CopyCreateContract = { code: 0xaf, value: {} };
exports.DestroySelf = { code: 0xb0, value: {} };
exports.SelfContractId = { code: 0xb1, value: {} };
exports.SelfAddress = { code: 0xb2, value: {} };
exports.CallerContractId = { code: 0xb3, value: {} };
exports.CallerAddress = { code: 0xb4, value: {} };
exports.IsCallerFromTxScript = { code: 0xb5, value: {} };
exports.CallerInitialStateHash = { code: 0xb6, value: {} };
exports.CallerCodeHash = { code: 0xb7, value: {} };
exports.ContractInitialStateHash = { code: 0xb8, value: {} };
exports.ContractInitialCodeHash = { code: 0xb9, value: {} };
exports.MigrateSimple = { code: 0xba, value: {} };
exports.MigrateWithFields = { code: 0xbb, value: {} };
exports.CopyCreateContractWithToken = { code: 0xbc, value: {} };
exports.BurnToken = { code: 0xbd, value: {} };
exports.LockApprovedAssets = { code: 0xbe, value: {} };
exports.CreateSubContract = { code: 0xbf, value: {} };
exports.CreateSubContractWithToken = { code: 0xc0, value: {} };
exports.CopyCreateSubContract = { code: 0xc1, value: {} };
exports.CopyCreateSubContractWithToken = { code: 0xc2, value: {} };
exports.LoadMutFieldByIndex = { code: 0xc3, value: {} };
exports.StoreMutFieldByIndex = { code: 0xc4, value: {} };
exports.ContractExists = { code: 0xc5, value: {} };
exports.CreateContractAndTransferToken = { code: 0xc6, value: {} };
exports.CopyCreateContractAndTransferToken = { code: 0xc7, value: {} };
exports.CreateSubContractAndTransferToken = { code: 0xc8, value: {} };
exports.CopyCreateSubContractAndTransferToken = { code: 0xc9, value: {} };
exports.NullContractAddress = { code: 0xca, value: {} };
exports.SubContractId = { code: 0xcb, value: {} };
exports.SubContractIdOf = { code: 0xcc, value: {} };
exports.AlphTokenId = { code: 0xcd, value: {} };
const LoadImmField = (index) => ({ code: 0xce, value: { index } });
exports.LoadImmField = LoadImmField;
exports.LoadImmFieldByIndex = { code: 0xcf, value: {} };
exports.PayGasFee = { code: 0xd0, value: {} };
exports.MinimalContractDeposit = { code: 0xd1, value: {} };
const CreateMapEntry = (immFields, mutFields) => ({ code: 0xd2, value: { immFields, mutFields } });
exports.CreateMapEntry = CreateMapEntry;
const MethodSelector = (selector) => {
    return { code: 0xd3, value: { index: selector } };
};
exports.MethodSelector = MethodSelector;
const CallExternalBySelector = (selector) => {
    return { code: 0xd4, value: { index: selector } };
};
exports.CallExternalBySelector = CallExternalBySelector;
class InstrCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .uint8('code')
            .choice('value', {
            tag: 'code',
            choices: {
                0x00: binary_parser_1.Parser.start().uint8('index'),
                0x01: binary_parser_1.Parser.start().uint8('index'),
                [exports.Return.code]: binary_parser_1.Parser.start(),
                [exports.ConstTrue.code]: binary_parser_1.Parser.start(),
                [exports.ConstFalse.code]: binary_parser_1.Parser.start(),
                [exports.I256Const0.code]: binary_parser_1.Parser.start(),
                [exports.I256Const1.code]: binary_parser_1.Parser.start(),
                [exports.I256Const2.code]: binary_parser_1.Parser.start(),
                [exports.I256Const3.code]: binary_parser_1.Parser.start(),
                [exports.I256Const4.code]: binary_parser_1.Parser.start(),
                [exports.I256Const5.code]: binary_parser_1.Parser.start(),
                [exports.I256ConstN1.code]: binary_parser_1.Parser.start(),
                [exports.U256Const0.code]: binary_parser_1.Parser.start(),
                [exports.U256Const1.code]: binary_parser_1.Parser.start(),
                [exports.U256Const2.code]: binary_parser_1.Parser.start(),
                [exports.U256Const3.code]: binary_parser_1.Parser.start(),
                [exports.U256Const4.code]: binary_parser_1.Parser.start(),
                [exports.U256Const5.code]: binary_parser_1.Parser.start(),
                0x12: binary_parser_1.Parser.start().nest('value', { type: compact_int_codec_1.compactSignedIntCodec.parser }),
                0x13: binary_parser_1.Parser.start().nest('value', { type: compact_int_codec_1.compactUnsignedIntCodec.parser }),
                0x14: binary_parser_1.Parser.start().nest('value', { type: bytestring_codec_1.byteStringCodec.parser }),
                0x15: binary_parser_1.Parser.start().nest('value', { type: lockup_script_codec_1.lockupScriptCodec.parser }),
                0x16: binary_parser_1.Parser.start().uint8('index'),
                0x17: binary_parser_1.Parser.start().uint8('index'),
                [exports.Pop.code]: binary_parser_1.Parser.start(),
                [exports.BoolNot.code]: binary_parser_1.Parser.start(),
                [exports.BoolAnd.code]: binary_parser_1.Parser.start(),
                [exports.BoolOr.code]: binary_parser_1.Parser.start(),
                [exports.BoolEq.code]: binary_parser_1.Parser.start(),
                [exports.BoolNeq.code]: binary_parser_1.Parser.start(),
                [exports.BoolToByteVec.code]: binary_parser_1.Parser.start(),
                [exports.I256Add.code]: binary_parser_1.Parser.start(),
                [exports.I256Sub.code]: binary_parser_1.Parser.start(),
                [exports.I256Mul.code]: binary_parser_1.Parser.start(),
                [exports.I256Div.code]: binary_parser_1.Parser.start(),
                [exports.I256Mod.code]: binary_parser_1.Parser.start(),
                [exports.I256Eq.code]: binary_parser_1.Parser.start(),
                [exports.I256Neq.code]: binary_parser_1.Parser.start(),
                [exports.I256Lt.code]: binary_parser_1.Parser.start(),
                [exports.I256Le.code]: binary_parser_1.Parser.start(),
                [exports.I256Gt.code]: binary_parser_1.Parser.start(),
                [exports.I256Ge.code]: binary_parser_1.Parser.start(),
                [exports.U256Add.code]: binary_parser_1.Parser.start(),
                [exports.U256Sub.code]: binary_parser_1.Parser.start(),
                [exports.U256Mul.code]: binary_parser_1.Parser.start(),
                [exports.U256Div.code]: binary_parser_1.Parser.start(),
                [exports.U256Mod.code]: binary_parser_1.Parser.start(),
                [exports.U256Eq.code]: binary_parser_1.Parser.start(),
                [exports.U256Neq.code]: binary_parser_1.Parser.start(),
                [exports.U256Lt.code]: binary_parser_1.Parser.start(),
                [exports.U256Le.code]: binary_parser_1.Parser.start(),
                [exports.U256Gt.code]: binary_parser_1.Parser.start(),
                [exports.U256Ge.code]: binary_parser_1.Parser.start(),
                [exports.U256ModAdd.code]: binary_parser_1.Parser.start(),
                [exports.U256ModSub.code]: binary_parser_1.Parser.start(),
                [exports.U256ModMul.code]: binary_parser_1.Parser.start(),
                [exports.U256BitAnd.code]: binary_parser_1.Parser.start(),
                [exports.U256BitOr.code]: binary_parser_1.Parser.start(),
                [exports.U256Xor.code]: binary_parser_1.Parser.start(),
                [exports.U256SHL.code]: binary_parser_1.Parser.start(),
                [exports.U256SHR.code]: binary_parser_1.Parser.start(),
                [exports.I256ToU256.code]: binary_parser_1.Parser.start(),
                [exports.I256ToByteVec.code]: binary_parser_1.Parser.start(),
                [exports.U256ToI256.code]: binary_parser_1.Parser.start(),
                [exports.U256ToByteVec.code]: binary_parser_1.Parser.start(),
                [exports.ByteVecEq.code]: binary_parser_1.Parser.start(),
                [exports.ByteVecNeq.code]: binary_parser_1.Parser.start(),
                [exports.ByteVecSize.code]: binary_parser_1.Parser.start(),
                [exports.ByteVecConcat.code]: binary_parser_1.Parser.start(),
                [exports.AddressEq.code]: binary_parser_1.Parser.start(),
                [exports.AddressNeq.code]: binary_parser_1.Parser.start(),
                [exports.AddressToByteVec.code]: binary_parser_1.Parser.start(),
                [exports.IsAssetAddress.code]: binary_parser_1.Parser.start(),
                [exports.IsContractAddress.code]: binary_parser_1.Parser.start(),
                0x4a: binary_parser_1.Parser.start().nest('value', { type: compact_int_codec_1.compactUnsignedIntCodec.parser }),
                0x4b: binary_parser_1.Parser.start().nest('value', { type: compact_int_codec_1.compactUnsignedIntCodec.parser }),
                0x4c: binary_parser_1.Parser.start().nest('value', { type: compact_int_codec_1.compactUnsignedIntCodec.parser }),
                [exports.Assert.code]: binary_parser_1.Parser.start(),
                [exports.Blake2b.code]: binary_parser_1.Parser.start(),
                [exports.Keccak256.code]: binary_parser_1.Parser.start(),
                [exports.Sha256.code]: binary_parser_1.Parser.start(),
                [exports.Sha3.code]: binary_parser_1.Parser.start(),
                [exports.VerifyTxSignature.code]: binary_parser_1.Parser.start(),
                [exports.VerifySecP256K1.code]: binary_parser_1.Parser.start(),
                [exports.VerifyED25519.code]: binary_parser_1.Parser.start(),
                [exports.NetworkId.code]: binary_parser_1.Parser.start(),
                [exports.BlockTimeStamp.code]: binary_parser_1.Parser.start(),
                [exports.BlockTarget.code]: binary_parser_1.Parser.start(),
                [exports.TxId.code]: binary_parser_1.Parser.start(),
                [exports.TxInputAddressAt.code]: binary_parser_1.Parser.start(),
                [exports.TxInputsSize.code]: binary_parser_1.Parser.start(),
                [exports.VerifyAbsoluteLocktime.code]: binary_parser_1.Parser.start(),
                [exports.VerifyRelativeLocktime.code]: binary_parser_1.Parser.start(),
                [exports.Log1.code]: binary_parser_1.Parser.start(),
                [exports.Log2.code]: binary_parser_1.Parser.start(),
                [exports.Log3.code]: binary_parser_1.Parser.start(),
                [exports.Log4.code]: binary_parser_1.Parser.start(),
                [exports.Log5.code]: binary_parser_1.Parser.start(),
                [exports.ByteVecSlice.code]: binary_parser_1.Parser.start(),
                [exports.ByteVecToAddress.code]: binary_parser_1.Parser.start(),
                [exports.Encode.code]: binary_parser_1.Parser.start(),
                [exports.Zeros.code]: binary_parser_1.Parser.start(),
                [exports.U256To1Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256To2Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256To4Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256To8Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256To16Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256To32Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256From1Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256From2Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256From4Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256From8Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256From16Byte.code]: binary_parser_1.Parser.start(),
                [exports.U256From32Byte.code]: binary_parser_1.Parser.start(),
                [exports.EthEcRecover.code]: binary_parser_1.Parser.start(),
                [exports.Log6.code]: binary_parser_1.Parser.start(),
                [exports.Log7.code]: binary_parser_1.Parser.start(),
                [exports.Log8.code]: binary_parser_1.Parser.start(),
                [exports.Log9.code]: binary_parser_1.Parser.start(),
                [exports.ContractIdToAddress.code]: binary_parser_1.Parser.start(),
                [exports.LoadLocalByIndex.code]: binary_parser_1.Parser.start(),
                [exports.StoreLocalByIndex.code]: binary_parser_1.Parser.start(),
                [exports.Dup.code]: binary_parser_1.Parser.start(),
                [exports.AssertWithErrorCode.code]: binary_parser_1.Parser.start(),
                [exports.Swap.code]: binary_parser_1.Parser.start(),
                [exports.BlockHash.code]: binary_parser_1.Parser.start(),
                0x7e: binary_parser_1.Parser.start().nest('stringParts', { type: byteStringArrayCodec.parser }),
                [exports.TxGasPrice.code]: binary_parser_1.Parser.start(),
                [exports.TxGasAmount.code]: binary_parser_1.Parser.start(),
                [exports.TxGasFee.code]: binary_parser_1.Parser.start(),
                [exports.I256Exp.code]: binary_parser_1.Parser.start(),
                [exports.U256Exp.code]: binary_parser_1.Parser.start(),
                [exports.U256ModExp.code]: binary_parser_1.Parser.start(),
                [exports.VerifyBIP340Schnorr.code]: binary_parser_1.Parser.start(),
                [exports.GetSegragatedSignature.code]: binary_parser_1.Parser.start(),
                [exports.MulModN.code]: binary_parser_1.Parser.start(),
                [exports.AddModN.code]: binary_parser_1.Parser.start(),
                [exports.U256ToString.code]: binary_parser_1.Parser.start(),
                [exports.I256ToString.code]: binary_parser_1.Parser.start(),
                [exports.BoolToString.code]: binary_parser_1.Parser.start(),
                [exports.GroupOfAddress.code]: binary_parser_1.Parser.start(),
                0xa0: binary_parser_1.Parser.start().uint8('index'),
                0xa1: binary_parser_1.Parser.start().uint8('index'),
                [exports.ApproveAlph.code]: binary_parser_1.Parser.start(),
                [exports.ApproveToken.code]: binary_parser_1.Parser.start(),
                [exports.AlphRemaining.code]: binary_parser_1.Parser.start(),
                [exports.TokenRemaining.code]: binary_parser_1.Parser.start(),
                [exports.IsPaying.code]: binary_parser_1.Parser.start(),
                [exports.TransferAlph.code]: binary_parser_1.Parser.start(),
                [exports.TransferAlphFromSelf.code]: binary_parser_1.Parser.start(),
                [exports.TransferAlphToSelf.code]: binary_parser_1.Parser.start(),
                [exports.TransferToken.code]: binary_parser_1.Parser.start(),
                [exports.TransferTokenFromSelf.code]: binary_parser_1.Parser.start(),
                [exports.TransferTokenToSelf.code]: binary_parser_1.Parser.start(),
                [exports.CreateContract.code]: binary_parser_1.Parser.start(),
                [exports.CreateContractWithToken.code]: binary_parser_1.Parser.start(),
                [exports.CopyCreateContract.code]: binary_parser_1.Parser.start(),
                [exports.DestroySelf.code]: binary_parser_1.Parser.start(),
                [exports.SelfContractId.code]: binary_parser_1.Parser.start(),
                [exports.SelfAddress.code]: binary_parser_1.Parser.start(),
                [exports.CallerContractId.code]: binary_parser_1.Parser.start(),
                [exports.CallerAddress.code]: binary_parser_1.Parser.start(),
                [exports.IsCallerFromTxScript.code]: binary_parser_1.Parser.start(),
                [exports.CallerInitialStateHash.code]: binary_parser_1.Parser.start(),
                [exports.CallerCodeHash.code]: binary_parser_1.Parser.start(),
                [exports.ContractInitialStateHash.code]: binary_parser_1.Parser.start(),
                [exports.ContractInitialCodeHash.code]: binary_parser_1.Parser.start(),
                [exports.MigrateSimple.code]: binary_parser_1.Parser.start(),
                [exports.MigrateWithFields.code]: binary_parser_1.Parser.start(),
                [exports.CopyCreateContractWithToken.code]: binary_parser_1.Parser.start(),
                [exports.BurnToken.code]: binary_parser_1.Parser.start(),
                [exports.LockApprovedAssets.code]: binary_parser_1.Parser.start(),
                [exports.CreateSubContract.code]: binary_parser_1.Parser.start(),
                [exports.CreateSubContractWithToken.code]: binary_parser_1.Parser.start(),
                [exports.CopyCreateSubContract.code]: binary_parser_1.Parser.start(),
                [exports.CopyCreateSubContractWithToken.code]: binary_parser_1.Parser.start(),
                [exports.LoadMutFieldByIndex.code]: binary_parser_1.Parser.start(),
                [exports.StoreMutFieldByIndex.code]: binary_parser_1.Parser.start(),
                [exports.ContractExists.code]: binary_parser_1.Parser.start(),
                [exports.CreateContractAndTransferToken.code]: binary_parser_1.Parser.start(),
                [exports.CopyCreateContractAndTransferToken.code]: binary_parser_1.Parser.start(),
                [exports.CreateSubContractAndTransferToken.code]: binary_parser_1.Parser.start(),
                [exports.CopyCreateSubContractAndTransferToken.code]: binary_parser_1.Parser.start(),
                [exports.NullContractAddress.code]: binary_parser_1.Parser.start(),
                [exports.SubContractId.code]: binary_parser_1.Parser.start(),
                [exports.SubContractIdOf.code]: binary_parser_1.Parser.start(),
                [exports.AlphTokenId.code]: binary_parser_1.Parser.start(),
                0xce: binary_parser_1.Parser.start().uint8('index'),
                [exports.LoadImmFieldByIndex.code]: binary_parser_1.Parser.start(),
                [exports.PayGasFee.code]: binary_parser_1.Parser.start(),
                [exports.MinimalContractDeposit.code]: binary_parser_1.Parser.start(),
                0xd2: binary_parser_1.Parser.start().uint8('immFields').uint8('mutFields'),
                0xd3: binary_parser_1.Parser.start().int32('index'),
                0xd4: binary_parser_1.Parser.start().int32('index')
            }
        });
    }
    encode(instr) {
        const instrValue = instr.value;
        const result = [instr.code];
        const instrsWithIndex = [0x00, 0x01, 0x16, 0x17, 0xa0, 0xa1, 0xce];
        const instrsWithCompactInt = [0x12, 0x13, 0x4a, 0x4b, 0x4c];
        if (instr.code === 0x14) {
            result.push(...bytestring_codec_1.byteStringCodec.encode(instrValue.value));
        }
        else if (instr.code === 0x15) {
            result.push(...lockup_script_codec_1.lockupScriptCodec.encode(instrValue.value));
        }
        else if (instr.code === 0x7e) {
            result.push(...byteStringArrayCodec.encode(instrValue.stringParts.value));
        }
        else if (instrsWithCompactInt.includes(instr.code)) {
            result.push(...compact_int_codec_1.compactUnsignedIntCodec.encode(instrValue.value));
        }
        else if (instrsWithIndex.includes(instr.code)) {
            result.push(instrValue.index);
        }
        else if (instr.code === 0xd2) {
            const value = instrValue;
            result.push(value.immFields, value.mutFields);
        }
        else if (instr.code === 0xd3 || instr.code === 0xd4) {
            result.push(...signed_int_codec_1.signedIntCodec.encode(instrValue.index));
        }
        return new Uint8Array(result);
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
exports.InstrCodec = InstrCodec;
exports.instrCodec = new InstrCodec();
exports.instrsCodec = new array_codec_1.ArrayCodec(exports.instrCodec);
