"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const compact_int_codec_1 = require("./compact-int-codec");
class ArrayCodec {
    constructor(childCodec, parser = ArrayCodec.arrayParser(childCodec.parser)) {
        this.childCodec = childCodec;
        this.parser = parser;
    }
    encode(input) {
        const result = [...compact_int_codec_1.compactSignedIntCodec.encodeI256(BigInt(input.length))];
        for (const element of input) {
            result.push(...this.childCodec.encode(element));
        }
        return new Uint8Array(result);
    }
    decode(input) {
        const result = this.parser.parse(input);
        return result.value.map((v) => this.childCodec.decode(v.value));
    }
    static arrayParser(parser) {
        return new binary_parser_1.Parser()
            .nest('length', {
            type: compact_int_codec_1.compactSignedIntCodec.parser
        })
            .array('value', {
            length: function (ctx) {
                return compact_int_codec_1.compactSignedIntCodec.toI32(this['length']);
            },
            type: parser
        });
    }
    fromArray(inputs) {
        return {
            length: compact_int_codec_1.compactSignedIntCodec.fromI32(inputs.length),
            value: inputs
        };
    }
}
exports.ArrayCodec = ArrayCodec;
