"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.contractCodec = exports.ContractCodec = void 0;
const binary_parser_1 = require("binary-parser");
const array_codec_1 = require("./array-codec");
const compact_int_codec_1 = require("./compact-int-codec");
const method_codec_1 = require("./method-codec");
const utils_1 = require("../utils");
const compactSignedIntsCodec = new array_codec_1.ArrayCodec(compact_int_codec_1.compactSignedIntCodec);
class ContractCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .nest('fieldLength', {
            type: compact_int_codec_1.compactSignedIntCodec.parser
        })
            .nest('methodIndexes', {
            type: compactSignedIntsCodec.parser
        })
            .buffer('methods', { readUntil: 'eof' });
    }
    encode(input) {
        return (0, utils_1.concatBytes)([
            compact_int_codec_1.compactSignedIntCodec.encode(input.fieldLength),
            compactSignedIntsCodec.encode(input.methodIndexes.value),
            input.methods
        ]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    decodeContract(input) {
        const halfDecoded = this.decode(input);
        const fieldLength = compact_int_codec_1.compactSignedIntCodec.toI32(halfDecoded.fieldLength);
        const methodIndexes = halfDecoded.methodIndexes.value.map((v) => compact_int_codec_1.compactSignedIntCodec.toI32(v));
        const methods = [];
        for (let i = 0, start = 0; i < methodIndexes.length; i++) {
            const end = methodIndexes[i];
            const method = method_codec_1.MethodCodec.toMethod(method_codec_1.methodCodec.decode(halfDecoded.methods.slice(start, end)));
            methods.push(method);
            start = end;
        }
        return { fieldLength, methods };
    }
    encodeContract(contract) {
        const fieldLength = compact_int_codec_1.compactSignedIntCodec.fromI32(contract.fieldLength);
        const methods = contract.methods.map((m) => method_codec_1.methodCodec.encode(method_codec_1.MethodCodec.fromMethod(m)));
        let count = 0;
        const methodIndexes = Array.from(Array(methods.length).keys()).map((index) => {
            count += methods[`${index}`].length;
            return count;
        });
        const halfDecoded = {
            fieldLength,
            methodIndexes: {
                length: compact_int_codec_1.compactSignedIntCodec.fromI32(methodIndexes.length),
                value: methodIndexes.map((value) => compact_int_codec_1.compactSignedIntCodec.fromI32(value))
            },
            methods: (0, utils_1.concatBytes)(methods)
        };
        return this.encode(halfDecoded);
    }
}
exports.ContractCodec = ContractCodec;
exports.contractCodec = new ContractCodec();
