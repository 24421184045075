"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsignedTxCodec = exports.UnsignedTxCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const utils_1 = require("../utils");
const script_codec_1 = require("./script-codec");
const compact_int_codec_1 = require("./compact-int-codec");
const input_codec_1 = require("./input-codec");
const asset_output_codec_1 = require("./asset-output-codec");
const hash_1 = require("./hash");
class UnsignedTxCodec {
    constructor() {
        this.parser = new binary_parser_1.Parser()
            .uint8('version')
            .uint8('networkId')
            .nest('statefulScript', {
            type: script_codec_1.statefulScriptCodecOpt.parser
        })
            .nest('gasAmount', {
            type: compact_int_codec_1.compactSignedIntCodec.parser
        })
            .nest('gasPrice', {
            type: compact_int_codec_1.compactUnsignedIntCodec.parser
        })
            .nest('inputs', {
            type: input_codec_1.inputsCodec.parser
        })
            .nest('fixedOutputs', {
            type: asset_output_codec_1.assetOutputsCodec.parser
        });
    }
    encode(decodedUnsignedTx) {
        return (0, utils_1.concatBytes)([
            new Uint8Array([decodedUnsignedTx.version, decodedUnsignedTx.networkId]),
            script_codec_1.statefulScriptCodecOpt.encode(decodedUnsignedTx.statefulScript),
            compact_int_codec_1.compactSignedIntCodec.encode(decodedUnsignedTx.gasAmount),
            compact_int_codec_1.compactUnsignedIntCodec.encode(decodedUnsignedTx.gasPrice),
            input_codec_1.inputsCodec.encode(decodedUnsignedTx.inputs.value),
            asset_output_codec_1.assetOutputsCodec.encode(decodedUnsignedTx.fixedOutputs.value)
        ]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
    encodeApiUnsignedTx(input) {
        const decoded = UnsignedTxCodec.fromApiUnsignedTx(input);
        return this.encode(decoded);
    }
    decodeApiUnsignedTx(input) {
        const decoded = this.parser.parse(input);
        return UnsignedTxCodec.toApiUnsignedTx(decoded);
    }
    static txId(unsignedTx) {
        return (0, utils_1.binToHex)((0, hash_1.blakeHash)(exports.unsignedTxCodec.encode(unsignedTx)));
    }
    static toApiUnsignedTx(unsigned) {
        const txId = UnsignedTxCodec.txId(unsigned);
        const txIdBytes = (0, utils_1.hexToBinUnsafe)(txId);
        const version = unsigned.version;
        const networkId = unsigned.networkId;
        const gasAmount = compact_int_codec_1.compactSignedIntCodec.toI32(unsigned.gasAmount);
        const gasPrice = compact_int_codec_1.compactUnsignedIntCodec.toU256(unsigned.gasPrice).toString();
        const inputs = input_codec_1.InputCodec.toAssetInputs(unsigned.inputs.value);
        const fixedOutputs = asset_output_codec_1.AssetOutputCodec.toFixedAssetOutputs(txIdBytes, unsigned.fixedOutputs.value);
        let scriptOpt = undefined;
        if (unsigned.statefulScript.option === 1) {
            scriptOpt = (0, utils_1.binToHex)(script_codec_1.scriptCodec.encode(unsigned.statefulScript.value));
        }
        return { txId, version, networkId, gasAmount, scriptOpt, gasPrice, inputs, fixedOutputs };
    }
    static fromApiUnsignedTx(unsignedTx) {
        const version = unsignedTx.version;
        const networkId = unsignedTx.networkId;
        const gasAmount = compact_int_codec_1.compactSignedIntCodec.fromI32(unsignedTx.gasAmount);
        const gasPrice = compact_int_codec_1.compactUnsignedIntCodec.fromU256(BigInt(unsignedTx.gasPrice));
        const inputsValue = input_codec_1.InputCodec.fromAssetInputs(unsignedTx.inputs);
        const inputs = input_codec_1.inputsCodec.fromArray(inputsValue);
        const fixedOutputsValue = asset_output_codec_1.AssetOutputCodec.fromFixedAssetOutputs(unsignedTx.fixedOutputs);
        const fixedOutputs = asset_output_codec_1.assetOutputsCodec.fromArray(fixedOutputsValue);
        const statefulScript = script_codec_1.statefulScriptCodecOpt.fromBytes(unsignedTx.scriptOpt ? (0, utils_1.hexToBinUnsafe)(unsignedTx.scriptOpt) : undefined);
        return { version, networkId, gasAmount, gasPrice, inputs, fixedOutputs, statefulScript };
    }
}
exports.UnsignedTxCodec = UnsignedTxCodec;
exports.unsignedTxCodec = new UnsignedTxCodec();
